import React from "react";
import { Button, Form } from "react-bootstrap";
export const EmailStep = ({
  email,
  setEmail,
  onSubmit,
  isLoading,
  registerSellerLoading,
  registerLoading,
}) => (
  <Form onSubmit={onSubmit}>
    <Form.Group className="mb-3">
      <Form.Label>Email address</Form.Label>
      <Form.Control
        type="email"
        placeholder="Enter email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
      />
    </Form.Group>
    <Button
      variant="primary"
      type="submit"
      disabled={isLoading || registerLoading || registerSellerLoading}
    >
      Send OTP
    </Button>
  </Form>
);
