export function parseJwt(token) {
  if (!token) {
    return;
  }
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace("-", "+").replace("_", "/");
  return JSON.parse(window.atob(base64));
}

export const handleApiRequest = async (apiCall) => {
  try {
    const response = await apiCall();
    // Check if the response status indicates an error
    if (response?.error?.status >= 400) {
      throw response; // Throw the response to trigger catch
    }

    return response; // Return response if it's successful
  } catch (error) {
    // console.log("error", error);
    // Throw the error so it can be caught by the calling function
    const errorMessage =
      error?.error?.data?.messages?.[0] ||
      error?.data?.message ||
      "Something went wrong!";
    throw new Error(errorMessage);
  }
};

export const validatePassword = (password) => {
  const errors = [];

  if (password.length < 6 || password.length > 16) {
    errors.push("Password must be between 6 and 16 characters.");
  }
  if (!/[A-Z]/.test(password)) {
    errors.push("Password must contain at least one uppercase letter.");
  }
  if (!/\d/.test(password)) {
    errors.push("Password must contain at least one number.");
  }
  // eslint-disable-next-line no-useless-escape
  if (!/[@$\.]/.test(password)) {
    errors.push(
      "Password must contain at least one special character (@, $, or .)."
    );
  }
  return errors;
};

export const validatePhoneNumber = (phone) => {
  const errors = [];
  const phoneNumberPattern = /^[0-9]+$/;
  if (phone.length > 10) {
    errors.push("Phone number cannot exceed 10 digits");
  }
  if (!phoneNumberPattern.test(phone)) {
    errors.push("Phone number can only contain numbers.");
  }
  return errors;
};
