import React from 'react';
import { useParams } from 'react-router-dom';
import { Card, Row, Col, ListGroup } from 'react-bootstrap';

const OrderDetail = () => {
  const { orderId } = useParams();

  // Sample data for orders
  const orders = [
    {
      id: 1,
      productName: 'Wireless Headphones',
      productImage: 'https://via.placeholder.com/200',
      price: 2500,
      quantity: 2,
      overallPrice: 5000,
      status: 'Shipped',
      buyerName: 'John Doe',
      buyerEmail: 'johndoe@example.com',
      buyerPhone: '123-456-7890',
      shippingAddress: '123 Main St, Anytown, USA',
    },
    {
      id: 2,
      productName: 'Smart Watch',
      productImage: 'https://via.placeholder.com/200',
      price: 3000,
      quantity: 1,
      overallPrice: 3000,
      status: 'Pending',
      buyerName: 'Jane Smith',
      buyerEmail: 'janesmith@example.com',
      buyerPhone: '987-654-3210',
      shippingAddress: '456 Elm St, Othertown, USA',
    },
  ];

  const order = orders.find(o => o.id === parseInt(orderId));

  return (
    <div style={{
      maxWidth: '100%',
      margin: '0',
      padding: '0',
      backgroundColor: '#eaeaea',
    }}>
      <Card style={{
        border: 'none',
        borderRadius: '12px',
        overflow: 'hidden',
        boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)',
        margin: '20px',
        backgroundColor: '#fff',
      }}>
        {order ? (
          <>
            <Card.Header style={{
              backgroundColor: '#0056b3', // Blue background
              color: 'white',
              textAlign: 'center',
              padding: '30px',
              fontSize: '2.5rem',
              fontWeight: '600',
              letterSpacing: '1px',
            }}>
              Order Summary
            </Card.Header>
            <Card.Body style={{
              padding: '40px',
            }}>
              <Row className="align-items-center">
                <Col md={6} className="text-center">
                  <img src={order.productImage} alt={order.productName} style={{
                    width: '200px',
                    height: '200px',
                    borderRadius: '10px',
                    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.15)',
                  }} />
                </Col>
                <Col md={6}>
                  <h2 style={{
                    fontSize: '2rem',
                    fontWeight: '600',
                    color: '#333',
                    marginBottom: '10px',
                  }}>{order.productName}</h2>
                  <div>
                    <p style={{
                      fontSize: '1.5rem',
                      fontWeight: 'bold',
                      color: '#28a745',
                      margin: '0',
                    }}>Price: ₹{order.price}</p>
                    <p style={{
                      fontSize: '1.5rem',
                      fontWeight: 'bold',
                      margin: '0',
                    }}>Quantity: {order.quantity}</p>
                    <p style={{
                      fontSize: '1.5rem',
                      fontWeight: 'bold',
                      color: '#28a745',
                      margin: '0',
                    }}>Total: ₹{order.overallPrice}</p>
                    <p style={{
                      fontSize: '1rem',
                      fontWeight: 'bold',
                      color: order.status === 'Shipped' ? '#28a745' : '#dc3545',
                      marginTop: '20px',
                    }}>
                      Status: <span style={{ fontWeight: 'bold' }}>{order.status}</span>
                    </p>
                  </div>
                </Col>
              </Row>

              <div style={{
                marginTop: '40px',
                padding: '20px',
                borderRadius: '8px',
                backgroundColor: '#f8f9fa',
                boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
              }}>
                <h4 style={{ marginBottom: '10px', color: '#0056b3' }}>Buyer Information</h4>
                <ListGroup>
                  <ListGroup.Item><strong>Name:</strong> {order.buyerName}</ListGroup.Item>
                  <ListGroup.Item><strong>Email:</strong> {order.buyerEmail}</ListGroup.Item>
                  <ListGroup.Item><strong>Phone:</strong> {order.buyerPhone}</ListGroup.Item>
                </ListGroup>
              </div>

              <div style={{
                marginTop: '20px',
                padding: '20px',
                borderRadius: '8px',
                backgroundColor: '#f8f9fa',
                boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
              }}>
                <h4 style={{ marginBottom: '10px', color: '#0056b3' }}>Shipping Address</h4>
                <p style={{ fontSize: '1rem' }}>{order.shippingAddress}</p>
              </div>

              <div style={{
                marginTop: '40px',
                padding: '20px',
                border: '1px solid #0056b3',
                borderRadius: '8px',
                backgroundColor: '#e2e6ea',
              }}>
                <h4 style={{ marginBottom: '10px', color: '#0056b3' }}>Order Details</h4>
                <div style={{
                  padding: '20px',
                  backgroundColor: '#ffffff',
                  border: '1px solid #e0e0e0',
                  borderRadius: '8px',
                  boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                }}>
                  <h5 style={{ color: '#333' }}>Order Summary</h5>
                  <Row>
                    <Col md={8}>
                      <h6 style={{ fontWeight: 'bold' }}>{order.productName}</h6>
                      <p>Price: ₹{order.price}</p>
                      <p>Quantity: {order.quantity}</p>
                    </Col>
                    <Col md={4} className="text-right">
                      <h6 style={{ fontWeight: 'bold' }}>₹{order.overallPrice}</h6>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col md={8}><strong>Total:</strong></Col>
                    <Col md={4} className="text-right">
                      <strong>₹{order.overallPrice}</strong>
                    </Col>
                  </Row>
                </div>
              </div>
            </Card.Body>
          </>
        ) : (
          <p style={{ textAlign: 'center', fontSize: '1.5rem', color: '#dc3545' }}>Order not found.</p>
        )}
      </Card>
    </div>
  );
};

export default OrderDetail;
