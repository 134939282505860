import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../components/Loader";
import Message from "../components/Message";
import ProductCarousel from "../components/ProductCarousel";
import { useAddToCartApiMutation } from "../slices/cartApiSlice";
import { addToCart } from "../slices/cartSlice";
import {
  useGetProductByIdQuery,
  useGetProductImageMutation,
} from "../slices/productsApiSlice";
import { handleApiRequest } from "../utils/helper";

const ProductDetailPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userInformation } = useSelector((state) => state?.auth);
  const cartItems = useSelector((state) => state.cart.cartItems);
  const [images, setImages] = useState([]);
  const [addToCartApi] = useAddToCartApiMutation();
  const { data, isLoading, error } = useGetProductByIdQuery(id);
  const [getImageData] = useGetProductImageMutation();
  const user_id = userInformation?.NameIdentifier;

  useEffect(() => {
    const getData = async () => {
      let res = await getImageData({ productId: id });
      setImages(res?.data?.data?.productImages);
    };
    getData();
  }, [getImageData, id]);

  const productDetails = data?.data?.productDetails;
  const isInCart = cartItems.some(
    (item) => item.product_id === productDetails?.product_id
  );

  const handleAddProductToCart = async (product) => {
    try {
      // await handleApiRequest(() =>
      //   addToCartApi({
      //     user_id,
      //     product_id: product_id?.toString(),
      //     quantity: 1,
      //   })
      // );
      // dispatch(addToCart({ ...productDetails, quantity: 1 }));
      if (userInformation) {
        await handleApiRequest(() =>
          addToCartApi({
            user_id,
            entity_id: product?.entity_id,
            product_id: product?.product_id?.toString(),
            quantity: 1,
          })
        );
        dispatch(addToCart({ ...product, quantity: 1 }));
      } else {
        dispatch(addToCart({ ...product, quantity: 1 }));
      }
    } catch (error) {
      toast.error(error?.message);
    }
  };

  if (isLoading) return <Loader />;
  if (error) return <Message variant="danger">{JSON.stringify(error)}</Message>;

  return (
    <Container
      className="my-2 p-2"
      style={{ backgroundColor: "#eaeaea", maxWidth: "1600px" }}
    >
      <Card
        style={{
          border: "none",
          borderRadius: "12px",
          overflow: "hidden",
          boxShadow: "0 10px 30px rgba(0, 0, 0, 0.1)",
          backgroundColor: "#fff",
          width: "100%",
          height: "100%", // Make the card take the full width of the container
        }}
      >
        <Card.Body style={{ padding: "40px" }}>
          <Row>
            <Col
              md={6}
              className="d-flex justify-content-center align-items-center vh-50"
            >
              {productDetails && (
                <ProductCarousel imageUrls={images} productId={id} />
              )}
            </Col>
            <Col
              md={6}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <h1
                style={{
                  fontSize: "2.5rem",
                  fontWeight: "700",
                  color: "#333",
                  marginBottom: "10px",
                }}
              >
                {productDetails?.productName}
              </h1>

              <h5 style={{ color: "#555", marginBottom: "10px" }}>
                Color:{" "}
                <span style={{ textTransform: "capitalize" }}>
                  {productDetails?.color}
                </span>
              </h5>

              <p
                className="text-muted"
                style={{ fontSize: "1.1rem", marginBottom: "10px" }}
              >
                Sold by: {productDetails?.shopName ?? "Test"}
              </p>

              <div style={{ textAlign: "right" }}>
                <h2
                  style={{
                    color: "#28a745",
                    fontSize: "2rem",
                    fontWeight: "bold",
                  }}
                >
                  ₹{productDetails?.price}
                </h2>
              </div>

              <div className="d-flex justify-content-end mt-3">
                <Button
                  variant="outline-success" // Changed to outline-success from Shop component
                  className="me-2"
                  disabled={isInCart}
                  onClick={() => handleAddProductToCart(productDetails)}
                >
                  {isInCart ? "Added to Cart" : "Add to Cart"}
                </Button>
                <Button
                  variant="outline-danger" // Changed to outline-danger from Shop component
                  className="ms-2"
                >
                  Buy Now
                </Button>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default ProductDetailPage;
