import React from "react";
import { Badge, Navbar } from "react-bootstrap";
import {
  AiOutlineBook,
  AiOutlineHome,
  AiOutlineShoppingCart,
  AiOutlineUser,
  AiOutlineShop, // Import shop icon
} from "react-icons/ai";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const BottomNavbar = () => {
  const { cartItems } = useSelector((state) => state.cart);
  const { userInformation } = useSelector((state) => state.auth);

  return (
    <Navbar
      fixed="bottom"
      className="justify-content-around navbar-glassmorph d-lg-none"
      style={{ borderTop: "1px solid grey", position: "sticky" }}
    >
      <Link to="/" className="nav-link d-flex flex-column align-items-center">
        <AiOutlineHome size={24} />
        <span className="small">Home</span>
      </Link>

      <Link
        to="/profile"
        className="nav-link d-flex flex-column align-items-center"
      >
        <AiOutlineUser size={24} />
        <span className="small">Account</span>
      </Link>

      <Link
        to="/cart"
        className="nav-link d-flex flex-column align-items-center nav-link-cart"
      >
        <AiOutlineShoppingCart size={24} />
        <span className="small">Cart</span>
        {cartItems?.length > 0 && (
          <Badge
            pill
            bg="success"
            className="nav-icon-pill"
            style={{ top: "-10px", left: "25px" }}
          >
            {cartItems?.length}
          </Badge>
        )}
      </Link>

      {userInformation?.isSeller && (
        <Link
          to="/shop"
          className="nav-link d-flex flex-column align-items-center"
        >
          <AiOutlineShop size={24} />
          <span className="small">My Shop</span>
        </Link>
      )}

      <Link
        to="/bookmarks"
        className="nav-link d-flex flex-column align-items-center"
      >
        <AiOutlineBook size={24} />
        <span className="small">Favourites</span>
      </Link>
    </Navbar>
  );
};

export default BottomNavbar;
