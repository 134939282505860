import React from "react";
import { Container } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import CategoriesTab from "../components/CategoriesTab";
import Loader from "../components/Loader";
import { useGetProductsByNameQuery } from "../slices/productsApiSlice";
import ProductListPage from "./SearchProductScreen";

export default function HomeScreen() {
  // eslint-disable-next-line no-unused-vars
  const { pageNumber, keyword } = useParams();
  const { data, isLoading } = useGetProductsByNameQuery({
    search: keyword || "",
  });
  const products = data && data?.data?.products;
  return (
    <React.Fragment>
      <Container>
        {!keyword ? (
          <>
            <CategoriesTab />
          </>
        ) : (
          <Link to={`/`} className="btn btn-light mb-4 mt-2">
            Go Back
          </Link>
        )}
        {isLoading ? (
          <div>
            <Loader />
          </div>
        ) : (
          <>
            {keyword && products && products?.length > 0 && (
              <>
                <ProductListPage products={products} keyword={keyword} />
              </>
            )}
          </>
        )}
      </Container>
    </React.Fragment>
  );
}
