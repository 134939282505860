// AdditionalInformation.js
import { useState } from "react";
import { Form } from "react-bootstrap";
import { useGetAllCategoryQuery } from "../../slices/productsApiSlice";

const AdditionalInformation = ({
  category,
  setCategory,
  isSubscribable,
  setIsSubscribable,
  isReturnable,
  setIsReturnable,
}) => {
  const { data } = useGetAllCategoryQuery();

  const categories =
    data?.data?.categories.map((categories) => categories.category) || [];

  const [deliverylist, setDeliveryList] = useState(["12", "3", "8"]);
  function updateList(params) {
    setDeliveryList((prev) => [...prev, params]);
  }

  return (
    <div className="category">
      <h2>Additional Information</h2>
      <Form.Group className="mb-2">
        <Form.Label>Category</Form.Label>
        <Form.Select
          id="category-type"
          value={category}
          onChange={(e) => setCategory(e.target.value)}
        >
          <option value="">Select a Category</option>
          {categories.map((cat, index) => (
            <option key={index} value={cat}>
              {cat}
            </option>
          ))}
        </Form.Select>
      </Form.Group>
      {/* <Form.Group className="d-flex"> */}
      {/* <Form.Check
          type="checkbox"
          id="is-subscribable"
          label="Subscribable"
          checked={isSubscribable}
          onChange={(e) => setIsSubscribable(e.target.checked)}
          className="me-3"
        /> */}
      {/* <Form.Check
          type="checkbox"
          id="is-returnable"
          label="Returnable"
          checked={isReturnable}
          onChange={(e) => setIsReturnable(e.target.checked)}
        /> */}
      {/* {isSubscribable ? (
          <>
            <h1>delivery time</h1>
            <input type="datetime-local" name="" id="" />
            <Button type="button" onClick={() => updateList()}>
              Add
            </Button>
            {deliverylist?.map((data) => (
              <ul>
                <li>{data}</li>
              </ul>
            ))}
          </>
        ) : (
          <></>
        )} */}
      {/* </Form.Group> */}
    </div>
  );
};

export default AdditionalInformation;
