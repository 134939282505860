/* eslint-disable no-unused-vars */
import React from "react";
import { Button, Container, Row, Col, Card } from "react-bootstrap";
import { FaEdit, FaPlus, FaTrash } from "react-icons/fa";
import { LinkContainer } from "react-router-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import Message from "../../components/Message";
import {
  useDeleteProductByIdMutation,
  useGetSellerProductsQuery,
} from "../../slices/productsApiSlice";
import { useSelector } from "react-redux";

const ProductListScreen = () => {
  const { sellerId } = useParams();

  const { userInformation } = useSelector((state) => state?.auth);
  const user_id = userInformation?.NameIdentifier;

  const navigate = useNavigate();
  const {
    data: products,
    isLoading,
    error,
    refetch,
  } = useGetSellerProductsQuery({
    entityID: user_id,
  });

  console.log(products);
  const [deleteProduct, { isLoading: loadingDeleteProduct }] =
    useDeleteProductByIdMutation();

  const handleCreateProduct = () => {
    navigate("/admin/createproducts");
  };

  const handleDeleteProduct = async (id) => {
    try {
      if (window.confirm("Are you sure you want to delete this product?")) {
        await deleteProduct(id).unwrap();
        toast.success("Product deleted successfully");
        refetch();
      }
    } catch (error) {
      toast.error(error?.data?.message || error.error);
    }
  };

  const handleSingleProductRedirection = (id) => {
    navigate(`/product/${id}`);
  };

  return (
    <Container className="mt-5 mb-5">
      {/* Shop Info and Management Buttons */}
      <Card
        className="shadow-sm p-4 mb-5"
        style={{
          borderRadius: "18px",
          backgroundColor: "#ffffff",
          border: "none",
          boxShadow: "0 8px 20px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Row className="align-items-center">
          <Col xs={12} md={6} className="text-center text-md-start">
            <h1
              style={{
                fontWeight: "700",
                fontSize: "2rem",
                color: "#333",
                marginBottom: "10px",
              }}
            >
              Products
            </h1>
          </Col>
          <Col xs={12} md={6} className="text-center text-md-end">
            <Button
              variant="outline-success"
              className="mb-3"
              onClick={handleCreateProduct}
              style={{
                padding: "10px 20px",
                borderRadius: "30px",
                fontSize: "1rem",
                borderColor: "#20c997",
                color: "#20c997",
                boxShadow: "0 4px 12px rgba(32, 201, 151, 0.3)",
                marginRight: "10px",
              }}
            >
              <FaPlus /> Add Product
            </Button>
          </Col>
        </Row>
      </Card>

      {/* Product List Section */}
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">
          {error?.data?.message || error.error}
        </Message>
      ) : (
        <div style={{ padding: "10px" }}>
          {products?.data?.products?.length > 0 ? (
            products?.data?.products?.map((product) => (
              <Card
                key={product.product_id}
                onClick={() =>
                  handleSingleProductRedirection(product?.product_id)
                }
                className="mb-4"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  borderRadius: "8px",
                  border: "1px solid #e0e0e0",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  padding: "0",
                  transition: "transform 0.3s ease-in-out",
                  overflow: "hidden",
                }}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.transform = "scale(1.02)")
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.transform = "scale(1)")
                }
              >
                {/* Product Image */}
                <div style={{ flex: "0 0 150px", overflow: "hidden" }}>
                  <img
                    src={product.image || "https://via.placeholder.com/150"}
                    alt={product.productName}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </div>

                {/* Product Details */}
                <div
                  style={{
                    flex: "1",
                    padding: "20px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <h5 style={{ fontWeight: "600", fontSize: "1.2rem" }}>
                      {product?.productName}
                    </h5>
                    <p>Price: {`${product.currency_mode} ${product.price}`}</p>
                    <p>Category: {product.category}</p>
                    <p>
                      Stock Status:
                      <span
                        style={{
                          color: product.stock_quantity > 0 ? "green" : "red",
                          fontWeight: "bold",
                          marginLeft: "5px",
                        }}
                      >
                        {product.stock_quantity > 0
                          ? "In Stock"
                          : "Out of Stock"}
                      </span>
                    </p>
                  </div>

                  {/* Action Buttons */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      borderTop: "1px solid #eee",
                      paddingTop: "10px",
                    }}
                  >
                    <Button
                      variant="outline-danger"
                      onClick={() => handleDeleteProduct(product.product_id)}
                      style={{ padding: "5px 10px", fontSize: "0.85rem" }}
                    >
                      <FaTrash /> Delete
                    </Button>
                    <LinkContainer
                      to={`/admin/editproduct/${product.product_id}`}
                    >
                      <Button
                        variant="outline-primary"
                        style={{ padding: "5px 10px", fontSize: "0.85rem" }}
                      >
                        <FaEdit /> Edit
                      </Button>
                    </LinkContainer>
                  </div>
                </div>
              </Card>
            ))
          ) : (
            <Message variant="info">No products found.</Message>
          )}
        </div>
      )}
    </Container>
  );
};

export default ProductListScreen;
