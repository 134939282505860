// export const BASE_URL =
//   process.env.NODE_ENV === "development" ? "http://localhost:5000" : "";
export const BASE_URL = "http://dev.suuq.in";
export const PRODUCTS_URL = `${BASE_URL}/api/Product`;
export const PRODUCT_UPLOAD_URL = "/api/upload";
export const USERS_URL = `${BASE_URL}/UserRegistration`;
export const SELLER_URL = `${BASE_URL}/api/Seller`;
export const CART_URL = `${BASE_URL}/api/UserCart`;
export const LOGIN_URL = `${BASE_URL}/api/Account/authenticate`;
export const ORDERS_URL = `${BASE_URL}/api/Order`;
export const PAYPAL_URL = "/api/config/paypal";
export const GET_SELLER_PRODUCT_URL = `${BASE_URL}/api/Seller`;
export const BOOKMARKS_URL = `${BASE_URL}/api/BookMark`;
