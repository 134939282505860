import React from "react";
import { Button, Card, Col, Container, Row, Spinner } from "react-bootstrap";
import { FaTrashAlt } from "react-icons/fa";
import { useSelector } from "react-redux";
import {
  useDeleteBookmarkMutation,
  useGetBookMarksByIdQuery,
} from "../slices/bookmarksApiSlice";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const BookMarkScreen = () => {
  const navigate = useNavigate();

  const { userInformation } = useSelector((state) => state?.auth);
  const user_id = userInformation?.NameIdentifier;

  const {
    data: bookmarks,
    isLoading,
    error,
    refetch,
  } = useGetBookMarksByIdQuery(user_id);

  const [deleteBookmark] = useDeleteBookmarkMutation();

  const handleDelete = async (bookmarkID) => {
    try {
      await deleteBookmark(bookmarkID).unwrap();
      // Refetch bookmarks to update the screen
      toast.success("Remove from favoraite");
      refetch();
    } catch (error) {
      console.error("Failed to delete bookmark: ", error);
    }
  };

  if (isLoading)
    return (
      <Container className="d-flex justify-content-center align-items-center vh-100">
        <Spinner animation="border" />
      </Container>
    );
  if (error) {
    navigate("/login");
  }

  return (
    <Container fluid style={{ padding: "20px" }}>
      <Row>
        {!bookmarks?.bookmarks.length > 0 ? (
          <div className="d-flex justify-content-center align-items-center vh-100">
            <p>No Favourites</p>
          </div>
        ) : (
          bookmarks?.bookmarks?.map((bookmark) => (
            <Col
              xs={12}
              md={4}
              lg={4}
              className="mb-4"
              key={bookmark.bookMarkID}
            >
              <Card className="border rounded">
                <Card.Body>
                  <Row>
                    <Col className="d-flex justify-content-center align-items-center">
                      <img
                        src="https://via.placeholder.com/100"
                        alt="Bookmark"
                        style={{ width: "100%", height: "auto" }}
                      />
                    </Col>
                    <Col>
                      <h4>{bookmark.productName || "No Name"}</h4>
                      <p>{bookmark.details || "No Details"}</p>
                      <p>
                        {bookmark.establishment_name || "No Establishment Name"}
                      </p>
                      <div className="d-flex justify-content-end">
                        <Button
                          variant="danger"
                          onClick={() => handleDelete(bookmark.bookMarkID)}
                        >
                          <FaTrashAlt />
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          ))
        )}
      </Row>
    </Container>
  );
};

export default BookMarkScreen;
