import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { FaBox, FaStore, FaUserEdit } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { BASE_URL } from "../constants";
import { logout, setCredentials } from "../slices/authSlice";
import { useGetMyOrdersQuery } from "../slices/orderApiSlice";
import { useProfileMutation } from "../slices/usersApiSlice";

const ProfileScreen = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [activeSection, setActiveSection] = useState("");
  const [expandedOrder, setExpandedOrder] = useState(null);
  const [showOrders, setShowOrders] = useState(false); // State to control order visibility

  const { userInformation } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [updateProfile, { isLoading }] = useProfileMutation();
  const userId = userInformation?.NameIdentifier;

  const {
    data: ordersData,
    error,
    isLoading: ordersLoading,
  } = useGetMyOrdersQuery(
    { userId },
    {
      skip: !showOrders,
    }
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (userInformation) {
      setEmail(userInformation.email);
      setName(userInformation.name);
    }
  }, [userInformation]);

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      const res = await updateProfile({ name, email }).unwrap();
      dispatch(setCredentials({ ...res }));
      toast.success("Profile updated successfully");
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  const handleMyOrdersClick = () => {
    setShowOrders(true); // Set to true to fetch orders
    setActiveSection("orders");
  };

  const handleEditUserClick = () => {
    setActiveSection("editUser");
  };

  const handleLogout = () => {
    dispatch(logout());
    toast.success("Logged out successfully");
  };

  const handleBackClick = () => {
    setActiveSection("");
    setShowOrders(false); // Reset orders visibility
  };

  const handleToggleOrderDetails = (orderId) => {
    setExpandedOrder((prevOrderId) =>
      prevOrderId === orderId ? null : orderId
    );
  };

  return (
    <Container>
      <Row>
        <Col md={3}>
          <h3 className="my-2 d-flex align-items-center">
            Hello, {userInformation?.Name}
          </h3>
        </Col>
      </Row>

      {!activeSection && (
        <div className="d-md-none">
          <Row className="text-center my-4">
            <Col xs={6}>
              <Button
                variant="outline-dark"
                className="w-100 my-2 d-flex align-items-center justify-content-center"
                onClick={handleMyOrdersClick}
              >
                <FaBox className="me-2" />
                Orders
              </Button>
            </Col>
            <Col xs={6}>
              <Button
                variant="outline-dark"
                className="w-100 my-2 d-flex align-items-center justify-content-center"
                onClick={handleEditUserClick}
              >
                <FaUserEdit className="me-2" />
                Profile
              </Button>
            </Col>
            <Col xs={6}>
              <Button
                variant="outline-dark"
                className="w-100 my-2 d-flex align-items-center justify-content-center"
                as={Link}
                to="/admin/sellers"
              >
                <FaStore className="me-2" />
                Seller
              </Button>
            </Col>
            <Col xs={6}>
              <Button
                variant="outline-dark"
                className="w-100 my-2"
                onClick={handleLogout}
              >
                Logout
              </Button>
            </Col>
          </Row>
        </div>
      )}

      {activeSection && (
        <Button variant="secondary" onClick={handleBackClick} className="mb-4">
          Back
        </Button>
      )}

      <div className="d-md-none">
        {activeSection === "editUser" && (
          <Row className="justify-content-md-center">
            <Col md={12}>
              <h2>Edit User</h2>
              <Form onSubmit={submitHandler}>
                <Form.Group controlId="name">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Form.Group>

                <Form.Group controlId="email" className="my-3">
                  <Form.Label>Email Address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Group>

                <Button type="submit" variant="primary">
                  {isLoading ? <Loader size="sm" /> : "Update"}
                </Button>
              </Form>
            </Col>
          </Row>
        )}

        {activeSection === "orders" && (
          <Row>
            <Col md={12}>
              <h2>
                {ordersData && ordersData.orders.length > 0
                  ? "Orders"
                  : "No Orders"}
              </h2>
              {ordersLoading ? (
                <Loader />
              ) : error ? (
                <Message variant="danger">
                  {error?.data?.message || error.error}
                </Message>
              ) : (
                ordersData &&
                ordersData.orders.length > 0 && (
                  <div className="order-cards">
                    {ordersData.orders.map((order) => (
                      <Card key={order?.order_id} className="mb-3">
                        <Card.Body>
                          <Row>
                            <Col md={8}>
                              {/* <h5>
                                Shop Name:{" "}
                                {order?.establishment_name
                                  ? order?.establishment_name
                                  : "Super Mart"}
                              </h5> */}
                              <p>
                                <strong>Total Price:</strong> ₹
                                {order?.total_price}
                              </p>
                              <p>
                                <strong>Total Items: </strong>
                                {order?.orderLists?.length}
                              </p>
                            </Col>
                          </Row>
                          {/* <Row className="d-flex align-items-center">
                            <Col xs="auto">
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant="secondary"
                                  id="dropdown-basic"
                                  onClick={() =>
                                    handleToggleOrderDetails(order?.order_id)
                                  }
                                >
                                  {expandedOrder === order?.order_id
                                    ? "Hide Details"
                                    : "Order Details"}
                                </Dropdown.Toggle>
                              </Dropdown>
                            </Col>
                            <Col xs="auto">
                              <Button
                                onClick={() =>
                                  navigate(`/order/${order.order_id}`)
                                }
                              >
                                View Details
                              </Button>
                            </Col>
                          </Row> */}

                          {/* Conditionally render order details when expanded */}
                          {expandedOrder === order?.order_id && (
                            <div className="mt-3">
                              {order?.orderLists.map((item) => (
                                <Card
                                  key={item.productID}
                                  className="mb-2"
                                  style={{ width: "380px" }}
                                >
                                  <Row className="g-0 align-items-center">
                                    <Col
                                      xs={4}
                                      className="p-2 d-flex justify-content-center"
                                    >
                                      {item?.imageName ? (
                                        <img
                                          src={`${BASE_URL}/api/Files/download/${item.imageName}`}
                                          alt={`Product ${item.productID}`}
                                          className="img-fluid"
                                          style={{
                                            maxHeight: "100px",
                                            maxWidth: "100px",
                                            objectFit: "contain",
                                          }}
                                        />
                                      ) : (
                                        <h6 className="text-center w-100">
                                          No Image found
                                        </h6>
                                      )}
                                    </Col>
                                    <Col xs={8} className="p-2 text-center">
                                      <Card.Body className="text-center">
                                        <Card.Title>
                                          {item.productName}
                                        </Card.Title>
                                        <Card.Text>
                                          <strong>Qty:</strong> {item.quantity}
                                        </Card.Text>
                                        <Card.Text>
                                          <strong>Price:</strong> ₹{item.price}
                                        </Card.Text>
                                      </Card.Body>
                                    </Col>
                                  </Row>
                                </Card>
                              ))}
                            </div>
                          )}
                        </Card.Body>
                      </Card>
                    ))}
                  </div>
                )
              )}
            </Col>
          </Row>
        )}
      </div>
    </Container>
  );
};

export default ProfileScreen;
