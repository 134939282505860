import React from "react";
import { Badge, Col, Container, Nav, Navbar, Row } from "react-bootstrap";
import { FaShoppingCart, FaUser } from "react-icons/fa";
import { LinkContainer } from "react-router-bootstrap";
// import { useLogoutMutation } from "../slices/usersApiSlice";
import { useSelector } from "react-redux";
import SearchBar from "./SearchBar";
import UserMenu from "./UserMenu";
export default function Header() {
  const { cartItems } = useSelector((state) => state.cart);
  const { userInformation } = useSelector((state) => state?.auth);
  return (
    <header>
      <Navbar expand="md" collapseOnSelect className="navbar-glassmorph">
        <Container>
          <Row className="align-items-center w-100">
            <Col xs={2} md={1} className="d-flex align-items-center">
              <LinkContainer to="/">
                <Navbar.Brand>suuq</Navbar.Brand>
              </LinkContainer>
            </Col>
            <Col
              xs={10}
              md={11}
              className="d-flex align-items-center flex-grow-1"
            >
              <SearchBar />
            </Col>
            {/* <Col xs={4} md={6} className="text-end d-none d-md-none">
              <Navbar.Toggle aria-controls="basic-navbar" />
            </Col> */}
          </Row>

          <Navbar.Collapse id="basic-navbar" className="collapse-class">
            <Nav className="ms-auto align-items-center">
              <LinkContainer to="/cart">
                <Nav.Link className="nav-link-cart d-none d-md-block">
                  <FaShoppingCart className="nav-icon" />
                  Cart
                  {cartItems?.length > 0 && (
                    <Badge pill bg="success" className="nav-icon-pill">
                      {cartItems?.length}
                    </Badge>
                  )}
                </Nav.Link>
              </LinkContainer>

              <div className="user-menu">
                {userInformation ? (
                  <UserMenu />
                ) : (
                  <LinkContainer to="/login">
                    <Nav.Link className="d-none d-md-block nav-link">
                      <FaUser className="nav-icon" />
                      Login
                    </Nav.Link>
                  </LinkContainer>
                )}
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
}
